import moment from 'moment';

/**
 * Check if the provided date is the current date (in other words, today).
 * @param {string} dateString - The date string to compare.
 * @returns {boolean} - True if the provided date is the current date, false otherwise.
 */
export function isCurrentDate(dateString) {
  const currentDate = moment().format('YYYY-MM-DD');
  return currentDate === dateString;
}

/**
 * Check if the date is valid.
 *
 * Receives selection type, selected day, month, and year to do so.
 *
 * The date is valid if:
 * - Either day or month is not filled.
 * - Both day and month are filled, but the year is not filled yet.
 *
 * If a day was selected:
 * - The date is considered valid since day is part of options created so that it's always valid.
 * - Only month and year selection can alter the day's validity.
 *
 * If month was selected:
 * - The day is valid if it's less than or equal to the days in the month and not after today.
 * - The year is considered valid.
 *
 * If year was selected:
 * - If the month is after the current month, the month is invalid but the day is considered valid.
 * - If it's the current month:
 *   - The day is valid if it's not after today.
 *   - The month is always considered valid.
 * - For any other month in the selected year:
 *   - Both day and month are considered valid.
 *
 * @param {string} selectionType - The type of date selection (day, month, year).
 * @param {string} selectedDay - The selected day with 2 digits (e.g., '01') or empty.
 * @param {string} selectedMonth - The selected month with 2 digits (e.g., '01') or empty.
 * @param {string} selectedYear - The selected year with 4 digits (e.g., '2024') or empty.
 * @returns {object} - An object with hasValidDayChange and hasValidMonthChange properties.
 */
export function isDateValid(selectionType, selectedDay, selectedMonth, selectedYear) {
  const validDayProperties = { hasValidDayChange: true, hasValidMonthChange: true };
  if (!selectedDay || !selectedMonth) return validDayProperties;
  if (!selectedYear) return validDayProperties;

  const selectedYearMonth = `${selectedYear}-${selectedMonth}`;
  const daysInMonth = moment(selectedYearMonth, 'YYYY-MM').daysInMonth();

  const selectedDate = moment(`${selectedYearMonth}-${selectedDay}`, 'YYYY-MM-DD');
  const isToday = isCurrentDate(selectedDate.format('YYYY-MM-DD'));

  const currentYear = moment().format('YYYY');
  const isCurrentYear = selectedYear === currentYear;

  const currentMonth = moment().format('MM');
  const isMonthAboveThreshold = selectedMonth > currentMonth && isCurrentYear;
  const isCurrentMonth = selectedMonth === currentMonth;

  const currentDay = moment().format('DD');
  const isDayAboveThreshold = selectedDay > currentDay && isCurrentYear && isCurrentMonth;
  const isDayInMonth = selectedDay <= daysInMonth;
  const isDayWithinBounds = isDayInMonth && !isDayAboveThreshold;
  const isDayValid = isDayWithinBounds && !isToday;

  if (selectionType === 'month') {
    return {
      hasValidDayChange: isDayValid,
      hasValidMonthChange: true,
    };
  }

  /**
   * Check if month is valid first, since the selection type is year.
   * The validation from this point is as stated above.
   */
  if (isMonthAboveThreshold) {
    return {
      hasValidDayChange: true,
      hasValidMonthChange: !isMonthAboveThreshold,
    };
  }

  if (isCurrentMonth && !isDayValid) {
    if (selectionType === 'day') {
      return {
        hasValidDayChange: false,
        hasValidMonthChange: true,
      };
    }

    return {
      hasValidDayChange: true,
      hasValidMonthChange: false,
    };
  }

  return {
    hasValidDayChange: isDayValid,
    hasValidMonthChange: (isCurrentMonth && isDayValid) || !isCurrentMonth,
  };
}

/**
 * Get the new day properties.
 *
 * If the day is valid, return the value and error as false.
 * Otherwise, return the default day and error as true.
 *
 * @param {Object} props - type, day, month, year, value
 * @param {string} props.type - The type of date part (day, month, year).
 * @param {string} props.day - The current day.
 * @param {string} props.month - The current month.
 * @param {string} props.year - The current year.
 * @param {string} props.value - The new value.
 * @returns {Object} The new day properties with hasValidDayChange and newDay.
 * - hasValidDayChange {boolean} - Whether the day is valid.
 * - hasValidMonthChange {boolean} - Whether the month is valid.
 * - newDay {string} - The new day.
 * - newMonth {string} - The new month.
 */
export const getNewDateProperties = ({ type, day, month, year, value }) => {
  const defaultDay = '';
  const defaultMonth = '';

  const dayToValidate = type === 'day' ? value : day;
  const monthToValidate = type === 'month' ? value : month;
  const yearToValidate = type === 'year' ? value : year;

  const { hasValidDayChange, hasValidMonthChange } = isDateValid(
    type,
    dayToValidate,
    monthToValidate,
    yearToValidate,
  );

  if (hasValidDayChange || hasValidMonthChange) {
    return {
      hasValidDayChange,
      hasValidMonthChange,
      newDay: hasValidDayChange ? dayToValidate : defaultDay,
      newMonth: hasValidMonthChange ? monthToValidate : defaultMonth,
    };
  }

  return {
    hasValidDayChange: false,
    hasValidMonthChange: false,
    newDay: defaultDay,
    newMonth: defaultMonth,
  };
};

export default { isCurrentDate, isDateValid, getNewDateProperties };
