import React from 'react';
import { useSelector } from 'react-redux';
import FaresModal from './FaresModal';
import 'ui/layouts/FaresResults';

const FaresHeader = () => {
  const {
    copies: { fares },
  } = useSelector((state) => state.whitelabelConfig);
  return (
    <div className="fares-grid fares-header">
      <div className="fares-header-space" />
      {fares?.faresTypes?.map((type) => (
        <FaresModal key={type.code} title={type.name} legend={fares.disclaimer} />
      ))}
    </div>
  );
};

export default FaresHeader;
